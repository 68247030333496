<template>
  <div>
    <!-- <form class="search" action="" @submit.prevent="search">
      <input
        placeholder="搜索商品名称"
        type="text"
        v-model="name"
        autocomplete="off"
        @keyup.enter="searchList"
      />
      <div class="img">
        <img
          src="../../../static/image/sousuo.png"
          alt=""
          @click="searchList"
        />
      </div>
      <div class="close" @click="back">取消</div>
    </form> -->
    <Header>{{ $route.query.key }}</Header>

    <div class="container" ref="scroll">
      <div class="def" v-if="!getMarketSearchResult.length && isSearch">
        <img src="../../../static/image/hezi2.png" alt="" />
        <p>暂无数据</p>
      </div>
      <div
        class="box"
        v-if="getMarketSearchResult && getMarketSearchResult.length"
      >
        <div
          class="shop_img"
          v-for="item in getMarketSearchResult"
          :key="item.id"
          @click="bazaar(item)"
        >
          <img :src="item.goods ? item.goods.listPic : ''" alt="" />
          <div class="shop_text">{{ item.goods ? item.goods.name : '' }}</div>
          <div class="info">{{ item.goods ? item.goods.nickName : '' }}</div>
          <div class="shop_mongy">
            <span class="shop_sem">￥</span>
            <span>{{ item.fee }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      params: {
        pageindex: 1,
        pagesize: 20,
      },
      getMarketSearchResult: [],
      isSearch: false,
      noMore: false,
    };
  },
  mounted() {
    this.noMore = false;
    if (this.$refs.scroll) {
      this.$refs.scroll.addEventListener('scroll', this.onScroll, true);
    }
    this.isSearch = false;
    this.getGoodmarketlist();
  },
  beforeDestroy() {
    if (this.$refs.scroll) {
      this.$refs.scroll.removeEventListener('scroll', this.onScroll);
    }
  },
  methods: {
    onScroll(e) {
      const target = e.target;
      if (target.clientHeight + target.scrollTop >= target.scrollHeight) {
        console.log('到底了');
        if (!this.noMore) {
          this.params.pageindex++;
          this.getGoodmarketlist('more');
        }
      }
    },
    back() {
      this.$router.back();
    },
    bazaar(item) {
      console.log(item);
      this.$router.push({
        path: '/bazaar',
        query: {
          id: item.goodsId,
          entrustId: item.id,
        },
      });
    },
    search() {
      return false;
    },
    searchList() {
      this.params.pageindex = 1;
      this.params.pagesize = 20;
      this.getGoodmarketlist();
    },
    getGoodmarketlist(isMore) {
      let params = Object.assign(
        {
          name: decodeURI(this.$route.query.key).replaceAll('**', '#'),
        },
        this.params,
      );
      this.$api.getMarketSearchResult(params).then((data) => {
        if (data.code == 0) {
          if (isMore) {
            this.getMarketSearchResult = this.getMarketSearchResult.concat(
              data.data || [],
            );
          } else {
            this.getMarketSearchResult = data.data || [];
          }
          if ((data.data || []).length < this.params.pagesize) {
            this.noMore = true;
          }
        }
        this.isSearch = true;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: fixed;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 0;
  background-color: #f1f1f1;
  overflow: auto;
}
.def {
  margin-top: 50px;
  text-align: center;
  p {
    font-size: 14px;
    color: #999;
  }
  img {
    width: 35%;
    margin-right: -25px;
  }
}
.search {
  display: flex;
  height: 65px;
  align-items: center;
  position: relative;
  justify-content: center;
}
.search .img {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  position: absolute;
  right: 40px;
  img {
    width: 100%;
    vertical-align: middle;
  }
}
.close {
  font-size: 14px;
  color: #aaa;
  margin-left: 10px;
  text-align: right;
  margin-right: 10px;
}
.search input {
  flex: 1;
  height: 33px;
  background-color: #f3f4f8;
  font-size: 12px;
  border: 0;
  border-radius: 15px;
  padding-left: 16px;
  margin-left: 12px;
}
.box {
  overflow: hidden;
  padding-top: 20px;
}
.anyTypelist_box {
  display: flex;
}
.anyTypelist {
  color: #999999;
  font-size: 16px;
  margin-left: 10px;
  line-height: 50px;
}

.anyTypelist_tow {
  font-size: 19px;
  margin-left: 20px;
  font-weight: 600;
  line-height: 50px;
}

.shop_img {
  background-color: #ffffff;
  margin-left: 17px;
  width: 163px;
  float: left;
  margin-bottom: 14px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px 2px rgba(212, 210, 210, 0.192);
  div {
    margin-left: 8px;
  }
  img {
    display: inline-block;
    width: 100%;
    height: 190px;
    border-radius: 5px;
  }
  .shop_text {
    font-size: 13px;
  }
  .info {
    font-size: 10px;
    color: #aaa;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
  }
  .shop_explain {
    font-size: 8px;
    color: #999999;
  }
  .shop_mongy {
    font-size: 13px;
    padding-bottom: 15px;
    .shop_sem {
      font-size: 8px;
    }
  }
}
</style>
